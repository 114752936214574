<template>
    <div class="table table-benef">
        <div class="table-head">
            <div class="tr">
                <div class="tr-data-wrap">
                    <div class="tr-header-data">
                        <div class="td td-id">
                            <span>RANKING</span>
                        </div>
                        <div class="td td-address">
                            <span>IDENTITY ADDRESS</span>
                        </div>
                        <div class="td td-seeds">
                            <span># OF SEEDS</span>
                        </div>
                    </div>

                    <div class="tr-main-data">
                        <div class="td">
                            <span>OPENING TIME</span>
                        </div>
                        <div class="td">
                            <span>LOCKING TIME</span>
                        </div>
                        <div class="td">
                            <span>ESTIMATED RETURN</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-body" v-if="investments && investments.length > 0">
            <!-- TABLE DATA -->
            <div v-for="(investment, id) of investments" :key="investment.id" class="tr" :class="{ active: showDetails }">
                <div class="tr-data-wrap">
                    <div class="tr-header-data">
                        <div class="td td-type td-mobile">
                            <span>{{ getBoxName() }}</span>
                        </div>
                        <div class="td td-id">
                            <span>#{{ id + 1 }}</span>
                        </div>
                        <div class="td td-address">
                            <span>{{ makeShort(investment.investor) }}</span>
                        </div>
                        <div class="td td-seeds">
                            <span class="td-value">
                                <i class="coin-icon"></i>
                                {{ investment.amount ? parseFloat(Number(investment.amount).toFixed(4)) : "0.00" }} BNB
                            </span>
                        </div>
                    </div>

                    <div class="tr-main-data">
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Opening Time</span>
                            <span>{{
                                new Date(investment.openTime * 1000).toLocaleString("en-EN", {
                                    hour: "numeric",
                                    minute: "numeric",

                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })
                            }}</span>
                        </div>
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Locking Time</span>
                            <span>{{ getLockingTime(investment) }}H</span>
                        </div>
                        <div class="td">
                            <span class="td-mobile td-mobile-title">Estimated seed return</span>
                            <span>{{ calcEstimatedReturn(investment) }} BNB</span>
                        </div>
                        <div class="td td-address td-mobile">
                            <span class="td-mobile td-mobile-title">Identity address:</span>
                            <span>{{ makeShort(investment.investor) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="table-body">
            <div class="tr tr-no-data">
                <div>
                    <p>No data yet</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import Config from "../../../config.json";
    var conf = Config[Config.env];
    export default {
        props: ["investments", "boxType", "plans"],
        data() {
            return {
                showDetails: false,
            };
        },
        computed: {
            ...mapState(["currentBlockchain", "preselectedChain"]),
            getChain() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return chainId;
            },
        },
        methods: {
            getBoxName(type) {
                return this.plans[this.boxType].PLAN_NAME;
            },
            makeShort(addr) {
                if (addr) {
                    return addr.slice(0, 5) + "..." + addr.slice(addr.length - 5, addr.length);
                }
            },
            getLockingTime(investment) {
                return (investment.expiryTime - investment.openTime) / 3600;
            },
            calcEstimatedReturn(investment) {
                let { expiryTime, openTime, amount, investReturnRate } = investment;
                let now = Math.floor(new Date().getTime() / 1000);
                if (now > expiryTime) {
                    now = expiryTime;
                }
                const passed = now - Number(openTime);
                const ratePerSecond = Number(investReturnRate) / Number(conf[this.getChain].CONSTANTS.INVEST_RATE_PERC_ROUNDING) / (expiryTime - openTime);
                return parseFloat(Number((amount * passed * ratePerSecond) / 100 + Number(amount)).toFixed(8));
            },
        },
    };
</script>
