<template>
    <div class="beneficiaries">
        <section class="section-filter">
            <div class="container">
                <div class="row row-filter">
                    <SelectPlan v-model="selectedBox" :plans="getPossiblePlans" />
                    <SelectNumber v-model="selectedEpoch" :maxEpoch="maxEpoch + 1" />
                    <!-- <SelectStatus /> -->

                    <!-- <BatchAction /> -->
                </div>
            </div>
        </section>
        <section class="section-table">
            <div class="container">
                <BenefTable :investments="getInvestments" :boxType="selectedBox" :plans="getPossiblePlans" />
            </div>
        </section>
    </div>
</template>

<script>
    // import SelectStatus from '../../components/Filters/SelectStatus.vue'
    // import BatchAction from '../../components/BatchAction.vue'

    import { mapState } from "vuex";
    import config from "../../../config.json";
    var conf = config[config.env];
    import BenefTable from "../../components/BoxData/BenefTable.vue";
    import SelectNumber from "../../components/Filters/SelectNumber.vue";
    import SelectPlan from "../../components/Filters/SelectPlan.vue";

    export default {
        name: "Beneficiaries",
        components: { SelectPlan, SelectNumber, BenefTable },
        data() {
            return {
                selectedBox: 0,
                selectedEpoch: null,
                maxEpoch: 0,
                fetchedExact: null,
            };
        },
        computed: {
            ...mapState(["boxToEpochs", "currentAddress", "preselectedChain", "allInvestments"]),
            getPossiblePlans() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return conf[chainId].PLANS;
            },
            getInvestments() {
                if (!this.allInvestments) {
                    return [];
                }
                const investments = this.allInvestments[this.selectedBox][this.selectedEpoch] || [];
                if (!investments?.length && this.fetchedExact) {
                    const array = this.fetchedExact[this.selectedBox][this.selectedEpoch] || [];
                    return array.sort((a, b) => b.openTime - a.openTime);
                }
                return investments.sort((a, b) => b.openTime - a.openTime);
            },
        },
        async mounted() {
            if (this.boxToEpochs) {
                this.maxEpoch = this.boxToEpochs[this.selectedBox];
                this.selectedEpoch = this.maxEpoch;
            } else {
                const intervalCheckStore = setInterval(async () => {
                    if (this.boxToEpochs) {
                        this.maxEpoch = this.boxToEpochs[this.selectedBox];
                        this.selectedEpoch = this.maxEpoch;
                        clearInterval(intervalCheckStore);
                        return;
                    }
                }, 300);
            }
        },
        watch: {
            selectedEpoch: async function (newVal, oldVal) {
                if (oldVal !== null && this.allInvestments) {
                    const dataForSelectedEpoch = this.allInvestments[this.selectedBox];

                    if (!dataForSelectedEpoch.hasOwnProperty(newVal)) {
                        const res = await this.$root.core.getInvestmentsForSpecific(this.selectedBox, newVal);

                        this.fetchedExact = { [this.selectedBox]: { [newVal]: res[this.selectedBox][newVal] } };
                    }
                }
            },
            selectedBox: function () {
                if (this.selectedEpoch !== this.maxEpoch) {
                    this.fetchedExact = null;
                    this.selectedEpoch = this.maxEpoch;
                }
            },
        },
    };
</script>
